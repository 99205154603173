@import "minima";

/* nunito-regular - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/nunito-v16-latin-regular.woff2") format("woff2"), url("../fonts/nunito-v16-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-700 - latin */
@font-face {
  font-family: "Nunito_700Bold";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/nunito-v16-latin-700.woff2") format("woff2"), url("../fonts/nunito-v16-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
body {
  // font: Helvetica, Arial, sans-serif;
  font: 400 14px/1.5 "Nunito", Helvetica, Arial, sans-serif;
  color: #47475f;
  background-color: #fafaff;
}
p {
  font-size: 1.2rem;
}

.site-logo {
  float: right;
}
.site-nav {
  display: none;
}
.site-footer .page-link {
  color: $grey-color;
  display: inline-block;
}
.site-footer .page-link:not(:last-child) {
  margin-right: 20px;
}
.site-footer .trigger {
  margin-bottom: 10px;
}
.next {
  float: right;
}
.post-title {
  font-size: 2rem;
}
.post-meta a {
  padding: 0 5px;
}
.footer-col-1 {
  width: -webkit-calc(30% - (#{$spacing-unit} / 2));
  width:         calc(30% - (#{$spacing-unit} / 2));
}
.footer-col-2 {
  width: -webkit-calc(25% - (#{$spacing-unit} / 2));
  width:         calc(25% - (#{$spacing-unit} / 2));
}

// Header menu

.header_buttons_container {
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  max-width: 1100px;
  height: 100%;
  align-items: center;
}

#menu_container {
  box-shadow: 5px 10px 10px rgba(90, 100, 117, 0.3);
  width: 180px;
  background: #fafaff;
  position: absolute;
  top: 52px;
  left: 0;
  transform: translateX(-190px);
  transition: transform 250ms ease-in-out;
  z-index: 200;
}

#menu:checked~#menu_container {
  transform: translateX(0);
}
header a:visited {
  color: #383838;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Nunito_700Bold", Helvetica, Arial, sans-serif;
}

/**
 * Links
 */
a {
  color: #fe46b0;
  text-decoration: none;
}
a:visited {
  color: #fe46b0;
}

.site-header {
  border-top: none;
  border-bottom: none;
  min-height: 52px;
  position: relative;
}

h1 {
  margin-top: 20px;
}

header {
  background: #232347;
  border-top: none;
  height: 52px;
  border-bottom: none;
}
header > a.site-logo {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
}
header > a.site-logo-mobile, #header_app {
  display: none;
}
header a img {
  border: 0 none;
  margin: 0 auto;
  height: 52px;
}
header a img.ball-logo {
  height: 42px;
  padding-right: 3px;
}
header a img.pinballmap-logo {
  height: 50px;
  margin-right: 13px;
}

header hr {
  height: 1px;
  background: #fe46b0;
  border: 0;
}

header ul {
  padding: 10px 0 10px 0;
  margin: 0;
  width: 100%;
}

header li {
  padding: 2px 0 6px 4px;
  margin: 0;
}

header ul li a {
  text-align: left !important;
  padding: 4px 15px;
  float: none;
  display: block;
  text-decoration: none;
  vertical-align: middle;
  width: auto !important;
  font-size: 16px;
  line-height: 16px;
  color: #383838;
}

header ul li a:hover {
  color: #47475f;
  background-color: #e0e0ff;
  text-decoration: none;
}

header #menu_button {
  margin-top: -8px;
}

#menu~label {
  background-color: #232347;
  cursor: pointer;
  padding: 8px 12px;
}

#menu {
  display: none;
}

#menu~label span {
  transition-duration: 0s;
  transition-delay: 0.2s;
  display: inline-block;
  width: 32px;
  height: 4px;
  background-color: #f8f18e;
  border-radius: 3px;
  text-align: left;
  cursor: pointer;
}

#menu~label span::after,
#menu~label span::before {
  transition-delay: 0.2s, 0s;
  transition-duration: 0.2s;
  transition-property: margin, transform;
  content: '';
  position: absolute;
  width: 32px;
  height: 4px;
  background-color: #f8f18e;
  border-radius: 3px;
}

#menu~label span::after {
  margin-top: 8px;
}

#menu~label span::before {
  margin-top: -8px;
}

#menu:checked~label span {
  background-color: transparent;
}

#menu:checked~label span:before,
#menu:checked~label span:after {
  transition-delay: 0s, 0.2s;
  margin-top: 0;
}

#menu:checked~label span:before {
  transform: rotate(45deg);
}

#menu:checked~label span:after {
  transform: rotate(-45deg);
}
/*-- End of Icomoon icon font section --*/
@media (max-width: 760px) { 
  .site-header {
    min-height: 46px;
  }
  header > a img.ball-logo {
    height: 30px;
  }
  header > a img.pinballmap-logo {
    height: 30px;
    margin-right: 0;
  }
  header {
    display: flex;
    align-items: center;
    justify-content: start;
    min-width: 320px;
    height: 46px;
  }
  header > a.site-logo {
    display: none;
  }
  header > a.site-logo-mobile {
    display: inline-block;
    float: none;
    margin: auto;
  }
  #header_app {
    display: inline-block;
    margin: auto;
  }
  
  #header_app a {
    font-size: 11px;
    padding: 3px 6px;
    border-radius: 10px;
    background-color: white;
    color: #383838;
  }
  header #menu_button {
    width: 32px;
    margin-left: 10px;
    margin-right: 8px;
    height: 26px;
    margin-top: 0;
  }
  header ul {
    padding: 0 0 10px 0;
    margin-top: 0;
  }
  
  header ul:before {
    display: none;
  }
  
  header ul li {
    display: block;
    line-height: 24px;
    background: none;
  }
  
  header ul li a {
    float: none;
    display: inline;
    background: transparent;
    font-size: 16px !important;
    border: none;
    line-height: 26px;
    text-shadow: none;
  }
  #menu ~ label {
    padding: 0;
    font-size: 14px;
    line-height: 1.15;
  }
  #menu_container {
    display: inline-block;
    text-align: left;
    margin: 0 auto;
    float: none;
    width: 100%;
    visibility: visible;
    box-shadow: 0 10px 10px rgba(90, 100, 117, 0.2);
    top: 46px;
    transform: translateX(-190vw);
  }
  #menu {
    margin: auto;
  }
}
@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}
